@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hyperlink {
    @apply rounded text-sm text-it-blue-400 ring-it-blue-400 ring-offset-2 transition-all duration-500 hover:text-it-blue-500 focus-visible:ring-2 active:text-it-blue-300;
  }

  .hyperlink-disabled {
    @apply hyperlink pointer-events-none text-it-grey-300 hover:text-it-grey-300 active:text-it-grey-300;
  }
}

@import "./pace-theme-flash.css";
@import "react-toastify/dist/ReactToastify.css";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--primary-font), Arial, sans-serif;
}

html,
body {
  max-width: 100vw;
}

body {
  @apply text-instatruck-gray-300;
  background: white;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
  margin-right: 2px;
}

::-webkit-scrollbar-track {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #9fadbc;
  border-radius: 100px;
  border: 2px solid white;
}

.sidebar::-webkit-scrollbar-thumb {
  border: 2px solid theme("colors.instatruck-blue.400");
}

input {
  background: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: theme("colors.it-grey.700") !important;
}

.react-datepicker__header {
  background: white !important;
  border: none !important;
}

.react-datepicker__day--selected {
  background: theme("colors.it-primary.500") !important;
  color: white !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: theme("colors.it-grey.700");
}

.react-datepicker__triangle::before {
  border-bottom-color: theme("colors.it-grey.300") !important;
}

.react-datepicker__triangle::after {
  border-bottom-color: white !important;
}

.react-datepicker__today-button {
  background: white !important;
  padding: 4px 0 !important;
  border: 1px solid theme("colors.it-grey.200") !important;
  border-radius: 8px;
  margin: 12px;

  &:hover {
    background: #f0f0f0 !important;
  }
}

.react-datepicker__navigation {
  top: 8px !important;
  align-items: flex-start !important;
}

#dashboard-map .gm-style {
  .gm-style-iw-c {
    padding: 0;

    button.gm-ui-hover-effect {
      display: none !important;
    }

    .gm-style-iw-d {
      overflow: hidden !important;
      max-height: unset !important;
    }
  }
}

.pac-container {
  z-index: 10000;
}

#bot-widget--expanded__text {
  display: none !important;
}

@media (screen(md)) {
  #bot-widget--expanded__text {
    display: block !important;
  }
}
